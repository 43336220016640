import { useState } from "react";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Form from '../../utilities/Forms';
import axios from 'axios';

export const RESET_PASSWORD_URL = process.env.REACT_APP_API_RESET_PASSWORD;

const Reset = () => {

    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [validate, setValidate] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const [showRepeatPassword, setShowRepeatPassword] = useState(false);
    const [apiErrors, setApiErrors] = useState();

    let params = useParams();
    let token = params.token;

    const validateResetPassword = () => {
        let isValid = true;

        let individualValidator = Form.individualValidator({
            password: {
                value: password,
                isRequired: true,
                regex: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/

            },
            repeatPassword: {
                value: repeatPassword,
                isRequired: true,
                regex: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/
            }

        });

        let twoPasswordValidator = Form.twoPasswordValidator(password, repeatPassword);

        if (individualValidator !== null) {
            setValidate({
                validate: individualValidator.errors
            })

            isValid = false;
        } else if (twoPasswordValidator !== null) {
            setValidate({
                validate: twoPasswordValidator.errors
            })

            isValid = false;
        }

        return isValid;
    }

    const resetPassword = (e) => {
        e.preventDefault();

        const validate = validateResetPassword();

        if (validate) {
            axios.post(RESET_PASSWORD_URL + token, {
                password: password,
                headers: {
                    'Accept-Language': 'es-ES'
                }
            }).then(res => {
                setValidate({});
                setPassword('');
                setRepeatPassword('');
                toast.success('La contraseña se ha cambiado correctamente! 👏', {
                    autoClose: 2000,
                    closeOnClick: true
                });
            }).catch((error) => {
                setValidate({});
                if (error.response) {
                    let messagesArray = [];
                    if (error.response.data.statusCode === 400) {
                        const messages = error.response.data.message;
                        for (let i = 0; i < messages.length; i++) {
                            messagesArray.push(messages[i].value);
                        }
                        setApiErrors(messagesArray);
                    } else if (error.response.data.statusCode === 404) {
                        const errorMessage = error.response.data.message;
                        messagesArray.push(errorMessage);
                        setApiErrors(messagesArray);
                    } else if (error.response.data.statusCode === 500) {
                        const messages = error.response.data.message;
                        for (let i = 0; i < messages.length; i++) {
                            messagesArray.push(messages[i].value);
                        }
                        setApiErrors(messagesArray);
                    }
                } else {
                    let messagesArray = [];
                    messagesArray.push('Ha ocurrido un error al intentar cambiar la contraseña');
                    setApiErrors(messagesArray);
                }
            });

        }


    }

    const togglePassword = (e) => {
        if (showPassword) {
            setShowPassword(false);
        } else {
            setShowPassword(true);
        }
    }

    const toggleRepeatPassword = (e) => {
        if (showRepeatPassword) {
            setShowRepeatPassword(false);
        } else {
            setShowRepeatPassword(true);
        }
    }


    return (
        <>
            <ToastContainer />
            <div className="row g-0 auth-wrapper">
                <div className="col-12 col-md-5 col-lg-6 h-100 auth-background-col">
                    <div className="auth-background-holder"></div>
                    <div className="auth-background-mask"></div>
                </div>
                <div className="col-12 col-md-7 col-lg-6 auth-main-col text-center">
                    <div className="d-flex flex-column align-content-end">
                        <div style={{ marginRight: '20%', marginLeft: '20%' }}>
                            <p style={{
                                color: '#5F666A', fontFamily: 'Montserrat', fontSize: '16px'
                            }}>Cambio contraseña</p>
                            <div className="auth-form-container text-start">
                                <form className="auth-form" method="POST" onSubmit={resetPassword} autoComplete={'off'}>
                                    <div className="password mb-3">
                                        <div className="input-group">
                                            <input type={showPassword ? 'text' : 'password'}
                                                className={`form-control ${validate.validate && validate.validate.password ? 'is-invalid ' : ''}`}
                                                name="password"
                                                id="password"
                                                value={password}
                                                placeholder="Contraseña"
                                                onChange={(e) => setPassword(e.target.value)}
                                            />

                                            <button type="button" className="btn btn-outline-primary btn-sm" onClick={(e) => togglePassword(e)} ><i className={showPassword ? 'far fa-eye' : 'far fa-eye-slash'} ></i> </button>

                                            <div className={`invalid-feedback text-start ${(validate.validate && validate.validate.password) ? 'd-block' : 'd-none'}`} >
                                                {(validate.validate && validate.validate.password) ? validate.validate.password[0] : ''}
                                            </div>
                                        </div>

                                    </div>

                                    <div className="password mb-3">
                                        <div className="input-group">
                                            <input type={showRepeatPassword ? 'text' : 'password'}
                                                className={`form-control ${validate.validate && validate.validate.repeatPassword ? 'is-invalid ' : ''}`}
                                                name="repeatPassword"
                                                id="repeatPassword"
                                                value={repeatPassword}
                                                placeholder="Repetir contraseña"
                                                onChange={(e) => setRepeatPassword(e.target.value)}
                                            />

                                            <button type="button" className="btn btn-outline-primary btn-sm" onClick={(e) => toggleRepeatPassword(e)} ><i className={showRepeatPassword ? 'far fa-eye' : 'far fa-eye-slash'} ></i> </button>

                                            <div className={`invalid-feedback text-start ${(validate.validate && validate.validate.repeatPassword) || (validate.validate && validate.validate.passwordNotMatch) || (apiErrors) ? 'd-block' : 'd-none'}`} >
                                                {(validate.validate && validate.validate.repeatPassword) ? validate.validate.repeatPassword[0] : ''}
                                                {(validate.validate && validate.validate.passwordNotMatch) ? validate.validate.passwordNotMatch : ''}
                                                {(apiErrors) ? apiErrors : ''}
                                            </div>
                                        </div>

                                    </div>

                                    <div className="text-center">
                                        <button type="submit" className="btn w-100 theme-btn mx-auto" style={{
                                            color: '#FFFFFF', fontFamily: 'Montserrat', fontSize: '16px', backgroundColor: '#F666AC'
                                        }}>Guardar contraseña</button>
                                    </div>
                                </form>

                                <hr />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}

export default Reset;
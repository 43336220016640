import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import Reset from "./components/views/Reset";
import Error404 from "./components/views/error/Error404";

const ResetPassword = () => {
  return (
    <Router>
      <Switch>
        <Route path='/reset-password/:token' component={Reset} />
        <Route path='/error/Error404' component={Error404} />
        <Route path="/">
          <Redirect to="/error/Error404" />
        </Route>
      </Switch>
    </Router>
  );
}

export default ResetPassword;

class Form {

    /**
    * Validate password
    * @param str 
    * @returns boolean
    */
    static validPasswordRegex(str) {
        let regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
        return regex.test(str);
    }

    /**
     * Form individualValidator
     * @param  obj 
     * @returns 
     */
    static individualValidator(obj) {
        let keys = Object.entries(obj);
        let results = [];
        let validations = null;

        keys.map((key) => {
            if ('isRequired' in key[1] && key[1].isRequired) {
                if (key[1].value.length === 0) {
                    results.push({
                        [key[0]]: [`Campo requerido.`]
                    });
                } else {
                    if ('regex' in key[1] && key[1].regex) {
                        let isValidPassword = Form.validPasswordRegex(key[1].value);
                        if (!isValidPassword) {
                            results.push({
                                [key[0]]: [`El campo debe de ser de 8 caracteres con un caracter y un número. Ejemplo: 12345678a`]
                            });
                        }

                    }

                }

            }

            return results;
        })

        results = Object.assign({}, ...results.map((result) => result))

        if (Object.keys(results).length > 0) {
            validations = {
                errors: results
            }
        } else {
            validations = null
        }

        return validations;
    }

    static twoPasswordValidator(password, repeatPassword) {
        let results = [];
        let validations = null;
        if (password !== repeatPassword) {
            results.push({
                'passwordNotMatch': [`Las contraseñas no coinciden`]
            });

        }

        results = Object.assign({}, ...results.map((result) => result))
        if (Object.keys(results).length > 0) {
            validations = {
                errors: results
            }
        } else {
            validations = null;
        }

        return validations;
    }
}

export default Form
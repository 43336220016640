const Error404 = () => {
  return (
    <>
      <div style={{ height: '100%', width: '100%', backgroundColor: '#ffffff', opacity: '0.8' }}>
        <div class='text-center'
          style={{ position: 'fixed', top: '30%', left: '50%', transform: 'translate(-50%, -50%)' }}>
          <img src='https://piby-forgot-password-404.s3.eu-west-1.amazonaws.com/error404.png' alt='404' height='200'
            style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', width: '200px', marginTop: '20px', marginBottom: '10px' }} />
          <div style={{ color: '#F666AC', fontFamily: 'Montserrat', fontSize: '22px', textAlign: 'center' }}>
            ¡Ups! !Algo no ha ido bien! La página que estás buscando no existe!.</div>
        </div>
      </div>

    </>
  )
}

export default Error404;
